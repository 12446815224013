import logoTopWhite from '../resources/img/logo-top-white.png'
import logoWhite from '../resources/img/logo-white.png'
import logoTopDark from '../resources/img/logo-top-dark.png'
import logoDark from '../resources/img/logo-dark.png'

const title = 'Blue Sky'

const appConfig = {
  appTitle: `${title} ::  CMS`,
  defaultSize: 'xs',
  logoTopWhite: logoTopWhite,
  logoWhite: logoWhite,
  logoTopDark: logoTopDark,
  logoDark: logoDark
}

export default appConfig
