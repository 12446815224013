import { collection, getDocs, query } from 'firebase/firestore'
import { database } from '../config/firebase'

const fetchData = async (content: string): Promise<number> => {
  const qRef = collection(database, content)
  const q = query(qRef)
  const querySnapshot = await getDocs(q)
  let val = 0

  querySnapshot.forEach((doc) => {
    //queryResults.push(doc.data())
    let d = doc.data()
    if (d.onHomepage?.order > val) val = d.onHomepage.order
  })

  return val + 1
}

export { fetchData }
