import {
  buildCollection,
  buildEntityCallbacks,
  buildProperty
} from '@camberi/firecms'

import slugify from 'slugify'
import { fetchData } from '../../lib/API'

type Article = {
  publish: boolean
  title: string
  sortOrder: number
  onHomepage: any
  slug: string
  type: string
  img: string
  introContent: string
  contentShort: string
  contentLong: Array<string>
  leftButton: object
  rightButton: object
  onEachPage: boolean
}

const articleCallbacks = buildEntityCallbacks({
  onPreSave: async ({ collection, path, entityId, values, status }) => {
    if (values.onHomepage?.publish && !values.onHomepage?.order) {
      values.onHomepage.order = await fetchData('articles')
    }

    values.sortOrder = values.onHomepage.order ?? 0

    if (values.contentLong?.length > 0) {
      values.slug = `/${slugify(values.title?.toLowerCase())}`
    } else {
      values.slug = null
    }
    return values
  }
})

const articleCollection = buildCollection<Article>({
  name: 'Artikel',
  singularName: 'Artikel',
  description: 'Artikelen op de website',
  path: 'articles',
  group: 'Main',
  initialSort: ['sortOrder', 'asc'],
  defaultSize: 'xs',

  permissions: ({ authController }) => ({
    edit: true,
    create: true,
    delete: true
  }),

  properties: {
    publish: {
      name: 'Publiceren?',
      validation: { required: true },
      dataType: 'boolean',
      defaultValue: true
    },

    title: {
      name: 'Titel',
      validation: { required: true },
      dataType: 'string',
      description: 'Indien type "slogan", dan hier de slogan!'
    },

    sortOrder: {
      name: 'Volgorde',
      description: 'Volgorde op homepage',
      dataType: 'number',
      disabled: true
    },

    img: buildProperty({
      name: 'Afbeelding',
      dataType: 'string',
      storage: {
        storeUrl: true,
        storagePath: 'images/article',
        acceptedFiles: ['image/*']
      }
    }),

    type: {
      name: 'Type',
      validation: { required: false },
      dataType: 'string',
      enumValues: {
        header: 'Header',
        article: 'Artikel',
        slogan: 'Slogan'
      },
      defaultValue: 'article'
    },

    onHomepage: buildProperty({
      dataType: 'map',
      name: 'Op homepage?',
      validation: { required: false },
      properties: {
        publish: {
          name: 'Publiceren op homepage?',
          dataType: 'boolean',
          defaultValue: true
        },
        order: {
          name: 'Volgorde',
          description: 'Volgorde op homepage',
          dataType: 'number',
          defaultValue: undefined
        }
      }
    }),

    slug: {
      name: 'Slug',
      dataType: 'string',
      disabled: true,
      description: 'Dit veld wordt berekend op basis van de Titel',
      columnWidth: 0
    },

    introContent: {
      name: 'Introductie tekst (blauw)',
      markdown: false,
      dataType: 'string',
      columnWidth: 300
    },

    contentShort: {
      name: 'Content Samenvatting',
      //validation: { required: true },
      markdown: true,
      dataType: 'string',
      columnWidth: 300
    },

    contentLong: {
      name: 'Content',
      dataType: 'array',
      oneOf: {
        typeField: 'type',
        valueField: 'content',
        properties: {
          image: {
            dataType: 'string',
            name: 'Afbeelding',
            storage: {
              storeUrl: true,
              storagePath: 'images/projects',
              acceptedFiles: ['image/*']
            }
          },
          paragraph: {
            name: 'Paragraaf',
            dataType: 'string',
            markdown: true,
            columnWidth: 300
          }
        }
      }
    },

    leftButton: buildProperty({
      dataType: 'map',
      name: 'Button Links',
      validation: { required: false },
      properties: {
        label: {
          name: 'Titel',
          description: 'Button tekst',
          dataType: 'string'
        },
        action: {
          name: 'Actie',
          description: 'Button URL',
          dataType: 'string'
        }
      }
    }),

    rightButton: buildProperty({
      dataType: 'map',
      name: 'Button Rechts',
      validation: { required: false },
      properties: {
        label: {
          name: 'Titel',
          description: 'Button tekst',
          dataType: 'string'
        },
        action: {
          name: 'Actie',
          description: 'Button URL',
          dataType: 'string'
        }
      }
    }),

    onEachPage: {
      name: 'Toon op elke pagina?',
      validation: { required: true },
      dataType: 'boolean',
      defaultValue: false
    }
  },

  callbacks: articleCallbacks
})

export default articleCollection
